import Config from './config';

export default async function runRequest(requestParams) {
    const response = await fetch(`${Config.backend.host}/${Config.backend.index}/search${requestParams}`, {
      method: "GET",
      headers: { "content-type": "application/json" }
    });

    return response.json();
  }
