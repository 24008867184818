import React from "react";

import buildRequest from "./buildRequest";
import runRequest from "./runRequest"
import buildState from "./buildState";
import Result from "./views/Result";
import SingleSelectOrderedFacet from "./views/SingleSelectOrderedFacet";
import FilteredPagingInfo from "./views/FilteredPagingInfo"
import SafeFacet from "./containers/SafeFacet";
import ClearFilters from "./components/ClearFilters"

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";

import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./styles.css";

import Config from './config';
require(`./config/css/${process.env.REACT_APP_SITE}.css`);

const SORT_OPTIONS = [
  {
    name: "Publication Date ↑",
    value: "pubDate",
    direction: "asc"
  },
  {
    name: "Publication Date ↓",
    value: "pubDate",
    direction: "desc"
  },
  {
    name: "Relevance",
    value: "",
    direction: ""
  },
];


export default function App() {

  const config = {
    // debug: true,
    alwaysSearchOnInitialLoad: true,
    initialState: {
      resultsPerPage: 30,
      sortDirection: "desc",
      sortField: "pubDate"
      // filters: [{"field": "edition", "values": ["latest"], "type": "any"}]
    },
    onSearch: async state => {
      const { resultsPerPage } = state;
      const requestParams = buildRequest(state);
      const response = await runRequest(requestParams);
      return buildState(response, resultsPerPage);
    }
  };

  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({ wasSearched, setFilter, setSearchTerm, filters, facets }) => ({ wasSearched, setFilter, setSearchTerm, filters, facets })}>
        {({ wasSearched, setFilter, setSearchTerm, filters, facets }) => {
          return (
            <div className="App">
              <ErrorBoundary>
                <Layout
                  header={
                    <>
                      <div className="header_info">
                        {Config.header.logo !== null && <a href={Config.header.logo.href}><img src={Config.header.logo.img} alt={Config.header.logo.alt} className="header_img_covid" /></a>}
                        <div className="header_text">
                          <div className="header_title_wrapper"><div className="header_title">{Config.header.title}</div></div>
                          <span className="header_intro">{Config.header.intro}</span>
                        </div>
                        <div className="header_right">
                          <div className="header_img_syllabus_wrapper header_img_main"><span className="header_poweredby header_poweredby_syllabus">Powered by</span>
                            <a href={"https://the-syllabus.com/"}><img className="header_img_syllabus" src={'img/syllabus_new.png'} alt="The Syllabus" /></a>
                          </div>
                          {Config.header.extra_logos}
                        </div>
                      </div>
                      <SearchBox
                        inputProps={{ placeholder: (typeof Config.header.placeholder === 'undefined') ? "Search" : Config.header.placeholder || "Search", autofocus: "true" }}
                        autocompleteSuggestions={false}
                        shouldClearFilters={false}
                      />
                    </>
                  }
                  sideContent={
                    <div>
                      {wasSearched && (
                        <Sorting label={"Sort by"} sortOptions={SORT_OPTIONS} />
                      )}
                      <SafeFacet
                        field="editionDate"
                        label="Edition"
                        filterType="any"
                        isFilterable={false}
                        show={5}
                      />
                      <SafeFacet
                        field="language"
                        label="Language"
                        filterType="any"
                        isFilterable={false}
                        show={6}
                      />
                      <SafeFacet
                        field="contentType"
                        label="Content Type"
                        filterType="any"
                        isFilterable={false}
                        show={99}
                      />
                      <SafeFacet
                        field="topic"
                        label="topic"
                        filterType="any"
                        isFilterable={true}
                      />
                      <SafeFacet
                        field="publication"
                        label="Publication"
                        filterType="any"
                        isFilterable={true}
                      />
                      <Facet
                        field="publishedAfter"
                        label="Published After"
                        show={500}
                        view={SingleSelectOrderedFacet}
                      />
                      <Facet
                        field="publishedBefore"
                        label="Published Before"
                        show={500}
                        view={SingleSelectOrderedFacet}
                      />
                      <SafeFacet
                        field="openAccess"
                        label="Access Restrictions"
                        show={2}
                        filterType="any"
                        isFilterable={false}
                      />
                    </div>
                  }
                  bodyContent={
                    <Results
                      titleField="title"
                      urlField="url"
                      shouldTrackClickThrough={false}
                      resultView={props => <Result {...props} setFilter={setFilter} setSearchTerm={setSearchTerm} />}
                    />
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo
                        view={props => <FilteredPagingInfo {...props} filters={filters} facets={facets} />}
                      />}
                      {wasSearched && <ClearFilters />}
                      {wasSearched && <ResultsPerPage
                        options={[30, 50, 100]}
                      />
                      }
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
