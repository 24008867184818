import React from "react";

export default {
    backend: {
        host: "https://the-politics-of-covid-19.com/search/api",
        index: "osf"
    },
    header: {
        logo: null,
        title: "OSF Research Archive",
    intro: 
        <span> {   
            "Browse and search all the pieces featured in the Research Digests since " +
            "inception as well as a much larger selection of items hand-selected for OSF across text, " + 
            "video and audio."
        }<br /><p style={{lineHeight: "5px", margin: 0 }}><br /></p>{ "For all the "} <a href="https://clients.the-syllabus.com/osf-bookshelf/">{"original back editions of the Digests"}</a>
        {" and to subscribe to "}<a href="https://clients.the-syllabus.com/osf-podcasts/">{"the OSF podcast feed"}</a>{"."}</span>
    }
};