import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import Newsletter from "./newsletter";


class AppWithTitle extends React.PureComponent {

  render () {
    return (
      <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/newsletter" element={<Newsletter />} />
            </Routes>

          <div className="sui-footer" style={{width: "100%", textAlign: "center", marginBottom: "20px"}}><span className="sui-footer-span" >Copyright ⓒ 2020, The Syllabus, All Rights Reserved</span></div>
      </BrowserRouter>
    )
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<AppWithTitle />, rootElement);
