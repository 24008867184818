import Moment from 'moment';

function mapAggsToFacet(aggregations, fieldName, overrideFieldName) {
  return [
    {
      field: overrideFieldName || fieldName,
      type: "value",
      data: aggregations[fieldName].map(bucket => ({
        // Boolean values and date values require using `key_as_string`
        value: bucket.label,
        count: bucket.value
      }))
    }
  ];
}

function aggsHaveField(aggregations, fieldName) {
  return aggregations &&
    aggregations[fieldName] &&
    aggregations[fieldName].length > 0;

}

function getValueFacet(aggregations, fieldName) {
    if (aggsHaveField(aggregations, fieldName)) {
      return mapAggsToFacet(aggregations, fieldName);
    }
  }

  // same as getValueFacet, but cheats and uses pubDate to populate the field of the facet 'fieldName'
  function getPublicationDateFilter(aggregations, fieldName) {
    if (aggsHaveField(aggregations, "pubDate")) {
      return mapAggsToFacet(aggregations, "pubDate", fieldName)
    }
  }

  export default function buildStateFacets(aggregations) {
    const publication = getValueFacet(aggregations, "publication");
    const topic = getValueFacet(aggregations,"topic");
    const language = getValueFacet(aggregations,"language");
    const contentType = getValueFacet(aggregations,"contentType");
    const openAccess = getValueFacet(aggregations,"openAccess");

    const publishedAfter = getPublicationDateFilter(aggregations,"publishedAfter");
    const publishedBefore = getPublicationDateFilter(aggregations,"publishedBefore");

    let editionDate;
    if (aggsHaveField(aggregations, "editionDate")) {
      Moment.locale('en');
      editionDate = getValueFacet(aggregations,"editionDate")
        .map(item => { return {
          ...item, 
          "data": item.data.sort((a, b) => (new Date(a.value) - new Date(b.value)))
                          .map(el => {return {...el, "label": Moment(el.value).format('MMMM DD')}}).reverse()
                        }
                      }
            );
    }

    const facets = {
      ...(publication && { publication }),
      ...(topic && { topic }),
      ...(language && { language }),
      ...(contentType && { contentType }),
      ...(openAccess && { openAccess }),
      ...(publishedAfter && { publishedAfter }),
      ...(publishedBefore && { publishedBefore }),
      ...(editionDate && { editionDate })
    };
  
    if (Object.keys(facets).length > 0) {
      return facets;
    }
  }
