import React from "react";
import { useEffect } from 'react';
import Config from './config';


export default function Newsletter() {

  useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=T2DeHJ";
      script.setAttribute('async',true);
      document.body.appendChild(script);
    }, []);

  return(
    <div className="newsletter_container">
      <div className="newsletter_wrapper">
        <div className={"klaviyo-form-" + Config.form.formId}></div>
      </div>
  </div>
  )
}
